import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";

// CORE
const Home = React.lazy(() => import("./pages/Core/Home/Index"));

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ResetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);

// UserPanel
const Panel = React.lazy(() => import("./pages/Panel/Home/Index.js"));
const Calendar = React.lazy(() => import("./pages/Panel/Calendar/Index.tsx"));
const AddChild = React.lazy(() => import("./pages/Panel/AddChild/Index.js"));
const Diagnozy = React.lazy(() => import("./pages/Panel/Diagnozy/Index.js"));
const TeczkaPacjenta = React.lazy(() =>
  import("./pages/Panel/TeczkaPacjenta/Index.js")
);

// Admin Panel
const Admin = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const AdminDzieci = React.lazy(() => import("./pages/Admin/Dzieci/Index.js"));
const AdminDziecko = React.lazy(() => import("./pages/Admin/Dziecko/Index.js"));
const AdminUzytkownicy = React.lazy(() =>
  import("./pages/Admin/Uzytkownicy/Index.js")
);
const AdminUzytkownik = React.lazy(() =>
  import("./pages/Admin/Uzytkownik/Index.js")
);
const AdminKalendarz = React.lazy(() =>
  import("./pages/Admin/Kalendarz/Index.tsx")
);
const AdminTeczki = React.lazy(() => import("./pages/Admin/Teczki/Index.js"));

function App() {
  const authCtx = useContext(UserContext);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          <Route index element={<Home />} />

          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/przypomnij-haslo" element={<ResetPassword />} />
          {/* {authCtx.isLoggedIn && ( */}
          <>
            <Route path="/moje-konto" element={<Panel />} />
            <Route path="/dodaj-dziecko" element={<AddChild />} />
            <Route path="/kalendarz/:child_id" element={<Calendar />} />
            <Route path="/diagnozy" element={<Diagnozy />} />
            <Route path="/teczka-pacjenta" element={<TeczkaPacjenta />} />

            {/* ADMIN */}

            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/dzieci" element={<AdminDzieci />} />
            <Route path="/admin/dzieci/:child_id" element={<AdminDziecko />} />
            <Route path="/admin/uzytkownicy" element={<AdminUzytkownicy />} />
            <Route
              path="/admin/uzytkownicy/:user_id"
              element={<AdminUzytkownik />}
            />
            <Route path="/admin/kalendarz" element={<AdminKalendarz />} />
            <Route path="/admin/teczki" element={<AdminTeczki />} />
          </>
          {/* )} */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
